import { ref, Ref } from "vue";
import { axiosClient, APP_BASE_URL } from "@/core/services/AxiosClient";
import { PriceListFilter } from "@/models";
import {
  PriceListServiceProxy,
  IPriceListDto,
  PriceListDto,
} from "@/shared/service-proxies/generated-proxies";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";

interface UsablePriceLists {
  priceLists: Ref<Array<IPriceListDto>>;
  priceList: Ref<IPriceListDto>;
  getPriceLists: (filters: PriceListFilter) => void;
  getPriceList: (id: string) => void;
  deletePriceList: (id: string) => Promise<boolean>;
  updateOrCreatePriceList: (priceList: PriceListDto) => void;
}

const usePriceLists = (): UsablePriceLists => {
  const priceLists: Ref<Array<IPriceListDto>> = ref(
    [] as unknown as Array<IPriceListDto>
  );

  const client = new PriceListServiceProxy(APP_BASE_URL, axiosClient);

  const priceList: Ref<IPriceListDto> = ref(null as unknown as IPriceListDto);

  const store = useStore();

  const getPriceLists = async (filters: PriceListFilter) => {
    const [sorting, skipCount, maxResultCount, cancelToken] = [
      ...Object.values(filters),
    ];
    await client
      .getAll(skipCount, maxResultCount, sorting)
      .then((data) => {
        priceLists.value = data.items as unknown as Array<IPriceListDto>;
      })
      .catch((error) => {
        store.commit(Mutations.SET_ERROR, error);
      });
  };

  const getPriceList = async (id: string) => {
    await client
      .get(id)
      .then((data) => {
        priceList.value = data;
      })
      .catch((error) => {
        store.commit(Mutations.SET_ERROR, error);
      });
  };

  const updateOrCreatePriceList = async (priceList: PriceListDto) => {
    if (priceList.id) {
      await client.update(priceList).catch((error) => {
        store.commit(Mutations.SET_ERROR, error);
      });
    } else {
      await client.create(priceList).catch((error) => {
        store.commit(Mutations.SET_ERROR, error);
      });
    }
  };

  const deletePriceList = async (id: string) => {
    const resp = await client
      .delete(id)
      .then(() => true)
      .catch((error) => {
        store.commit(Mutations.SET_ERROR, error);
        return false;
      });

    return resp;
  };

  return {
    priceLists,
    priceList,
    getPriceLists,
    getPriceList,
    deletePriceList,
    updateOrCreatePriceList,
  };
};

export default usePriceLists;
