
import { defineComponent, ref, onMounted, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import usePriceList from "@/composables/usePriceList";
import {
  IPriceListDto,
  PriceListDto,
} from "@/shared/service-proxies/generated-proxies";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "CreateOrEditPriceList",
  components: {},
  setup() {
    const { priceList, getPriceList, updateOrCreatePriceList } = usePriceList();
    const route = useRoute();
    const router = useRouter();
    const saving = ref(false);
    const priceListId = computed(() => route.query.id);
    const store = useStore();
    const breadcrumbs = {
      title: "PriceList Edit",
      breadcrumbs: ["PriceList", "Edit"],
    };

    const rules = ref({
      name: [
        {
          required: true,
          message: "Price list name is required",
          trigger: "change",
        },
      ],
    });

    const defaultData = ref({
      name: "",
      description: "",
      // isActive: false,
    }) as unknown as IPriceListDto;

    let formData = ref<IPriceListDto>(defaultData);

    const submit = async () => {
      saving.value = true;

      await updateOrCreatePriceList(formData.value as unknown as PriceListDto);

      const error = store.getters.getErrors;

      if (!error) {
        Swal.fire({
          text: priceListId.value
            ? "Price list updated successfully"
            : "Price list created successfully",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Okay!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-success",
          },
        });
        router.push({ name: "priceLists" });
      } else {
        Swal.fire({
          text: error,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      }
      saving.value = false;
    };

    onMounted(async () => {
      if (priceListId.value) {
        setCurrentPageBreadcrumbs("Edit Price List", ["Price List", "Edit"]);
      } else {
        setCurrentPageBreadcrumbs("Create Price List", [
          "Price List",
          "Create",
        ]);
      }

      if (priceListId.value) {
        await getPriceList(priceListId.value as string);

        if (priceList.value) {
          formData.value = { ...priceList.value };
        }
      }
    });

    return {
      formData,
      saving,
      route,
      priceList,
      rules,
      submit,
      breadcrumbs,
    };
  },
});
